import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"

const images = [{url:"/angela-goh-1.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{url:"/angela-goh-3.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Play Ball </div><div className="dimensions"><span>Clay</span><span>30 x 22 x 13 cm</span>
</div></div>}]
const images_highlight = [
  {url:"/adam-lunar.webp", details:<div><div className="title">Adam Gottlieb</div><div className="materials">Lunar Diptych</div><div className="dimensions"><span>Water colour, pigment, hide glue</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/adam-shadow.webp", details:<div><div className="title">Adam Gottliebs</div><div className="materials">Shadow Form #1</div><div className="dimensions"><span>Water colour, pigment, hide glue</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/adam-void-5.webp", details:<div><div className="title">Adam Gottlieb</div><div className="materials">Void #5</div><div className="dimensions"><span>Water colour, pigment, hide glue</span><span>125cm x 55m</span>
</div></div>
}]
const images_highlight2 = [
  {url:"/adam-void-8.webp", details:<div><div className="title">Adam Gottlieb</div><div className="materials">Void #8</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/adam-imago.webp", details:<div><div className="title">Adam Gottlieb</div><div className="materials">Imago</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/adam-illuminations-2.webp", details:<div><div className="title">Adam Gottlieb</div><div className="materials">Illuminations #2</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]

const IndexPage = () => (<div>
  <Layout >
    <SEO title="Adam Gottlieb" />
    <div style={{ }}>
      <div style={{textAlign:"center"}} className={"titles inline"}><Link style={{textDecoration:"none", color:"black"}} to="/artist-2"><h3 style={{marginRight:"0px", marginBottom:"20px",marginTop:"100px", textAlign:"center"}}>Adam Gottlieb</h3></Link></div>
      <h1 style={{textAlign:"center", fontSize:"36px", marginBottom:"10vh"}}>Lunar Diptych</h1>
        <ImageRow images={[{size:"full", url:"/adam-lunar.webp", details:<div><div className="title">Adam Gottlieb</div><div className="materials">Lunar Diptych</div><div className="dimensions"><span>Water colour, pigment, hide glue</span><span>125cm x 55m x 55cm</span>
      </div></div>
  }]} />
<ImageRow  images={[{type:"text", text: <div><p style={{padding:"36px 20px 40px 20px", textAlign: "center", fontSize:"18px"}}><i>Watercolour, pigment & hide glue on handmade paper</i><br />2018<br />100 x 142 cm</p></div>, span:"12"}] } />
<div className={"titles inline"}><h3>Enquire about this artwork</h3></div>
<ImageRow  images={[{type:"text", text: <div><p style={{padding:"40px 20px", textAlign: "left", fontSize:"18px"}}>Vestibulum mollis, lectus a placerat pellentesque, velit est hendrerit nibh, ac sollicitudin massa justo elementum massa. Aenean semper arcu lorem, eget consequat nisl mollis ac. Integer quis odio aliquam, imperdiet felis interdum, luctus leo. Praesent ac rutrum nisl. Nam tempus mauris vitae congue placerat. Donec convallis placerat ex non scelerisque. Cras vehicula malesuada velit. Pellentesque vitae posuere augue. Nunc mollis varius eleifend. Fusce condimentum felis vitae elit consectetur vulputate.
<br /><br />

Phasellus ipsum nibh, auctor a dapibus et, vulputate vel nunc. Nunc vulputate auctor odio, a lacinia dolor imperdiet in. Proin eleifend massa vel ante lacinia, et pulvinar nisi sodales. Morbi vel tincidunt nunc, a ultrices risus. Mauris sagittis eleifend massa. Suspendisse convallis dictum hendrerit. Nullam sed nisl eu sapien dapibus fringilla. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer vitae dictum erat, id accumsan quam. Sed at tellus tortor. Cras congue egestas tempus. Sed fringilla, neque at placerat porta, est lorem tincidunt massa, ut molestie erat turpis feugiat arcu.</p></div>, span:"12"}] } />

<Break />  

    <ImageRow images={[{type:"text", text: <div>04.02.2021  ⟶ Adam Gottlieb's Lunar Diptych wins the Marchel Duchamp Prize</div>, span:"12"}] } />
    <Break />  
      <Titles titles={{"h2":"More works by Adam Gottlieb for sale"}} />
      
      <ImageRow images={images_highlight} />
        <ImageRow images={images_highlight2} />


    </div>

  </Layout>


</div>
)

export default IndexPage
